.loader-container {
    display: block;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .loader {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #3c54bc; /* Cambia el color del spinner */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 0.5s linear infinite;
    margin: auto;
  }

  .loader-text {
    width: 100%;
    text-align: center;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  