.consums-container {
    background: #F1F2F4;
    display: grid;
    flex-direction: column;
    justify-content: center;
}

.consums-title {
    color: #01669C;
    font-size: 2rem;
    font-weight: 900;
    margin: 2rem 0;
    text-transform: uppercase;
    text-align: center;
}

.consums-content {
    background: white;
    width: 100%;
    display: grid;
}

/* Selector de tipos de consumos*/
.btn-consums_container {
    display: flex;
    padding-bottom: 0.2rem;
    justify-content: center;
    grid-gap: 40%;
    position: relative;
    justify-self: center;
    margin-top: 1rem;
}

.btn-consums_selector {
    display: grid;
    border: 0;
    background: transparent;
    z-index: 1;
    cursor: pointer;
}

.btn-consums_icon {
    font-size: 2rem;
    color: #a7a7a7;
}

.btn-consums_text {
    font-size: .8rem;
    color: #01669C;
}

.line {
    width: 200%;
    height: 1px;
    position: absolute;
    bottom: 7%;
    background: #a7a7a7;
}

.active_color {
    color: #01669C !important;
}

.b-active {
    border-bottom: solid #01669C;
}

/* Calendarios consumos*/
.consums-calendar_container {
    display: grid;
    justify-content: center;
    padding: 2rem 0 0;
    grid-gap: 1.5rem;
    position: relative;
}

.consums-calendar_btn {
    background: #3861fd;
    color: white;
    height: 2rem;
    width: 20rem;
    border-radius: 1rem;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    box-shadow: 10px 7px 6px 2px rgba(0, 0, 0, 0.2);
    border: none;
    cursor: pointer;
}

.consums-calendar {
    z-index: -1;
    position: absolute;
    transition: all 0s ease-out;
    height: auto;
    top: -200%;
    justify-self: center;
}

.calendar-active {
    z-index: 1 !important;
    position: relative;
    top: 0;
    transition: all .5s cubic-bezier(0, -0.04, 0.33, 1.28);
}

/* Calendarios grid */
.calendary-container {
    display: grid;
    justify-content: center;
    text-align: center;
    grid-gap: 1rem;
    margin: 1rem 0;
    color: #a7a7a7;

    box-shadow: 16px 12px 15px 6px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    border-radius: 1rem;
}

.calendary {
    text-align: center;
    height: 1.2rem;
    border: none;
    background: transparent;
    font-size: 1rem;
    font-weight: 900;
    color: #a7a7a7;
}

.calendary-container span {
    font-weight: 700;
}

/* Lista de consumos */
.consums-list_container {
    width: 100vw;
    margin: 2rem 0;
    display: grid;
}

.consums-list_content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-content: center;
    margin: 0 20%;
    gap: 1rem;
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 40vh;
}

.consums-list_btn {
    background: #30ce2a;
    color: white;
    height: 2rem;
    width: 20rem;
    border-radius: 1rem;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    box-shadow: 10px 7px 6px 2px rgba(0, 0, 0, 0.2);
    border: none;
    cursor: pointer;
    justify-self: center;
    margin-bottom: 1.5rem;
}

.consums-list_element {
    display: flex;
    gap: 1rem;
    border: 0.2rem solid #a7a7a7;
    border-radius: 1rem;
}

.consums-list_calendar {
    padding: 6%;
    font-size: 2rem;
    color: #8e8e8e;
    border-right: 0.3rem solid #a7a7a7;
    display: grid;
    justify-content: center;
    align-content: center;
}

.consums-list_desc {
    font-size: 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: #8e8e8e;
    margin-right: 5%;
}

/* Descarga de consumos */

.consums-download_container {
    display: grid;
    justify-content: center;
    margin: 2rem;
    margin-bottom: 0;
}

.consums-download_btn {
    display: grid;
    cursor: pointer;
    font-size: 2rem;
    background: none;
    border: none;
    color: #8e8e8e;
}

.consums-download_btn:active {
    color: #01669C !important;
}

.consums-download_btn span {
    font-size: 1rem;
    font-weight: 500;
}

.consums-download_btn svg {
    justify-self: center;
}

/* Utilitys */
.consums-btn_navigation {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin: 2rem 0;
}

.btn-navigation {
    border-radius: 0.5rem;
    padding: 0.5rem 1.2rem;
    border: none;
    font-size: 1.2rem;
    display: flex;
    gap: .5rem;
    cursor: pointer;
    text-decoration: none;
    box-shadow: 10px 7px 6px 2px rgba(0, 0, 0, 0.2);
}

.btn-navigation svg {
    font-size: 2rem;
    justify-self: end;
    align-self: end;
}

.btn-navigation span {
    align-self: center;
}

.btn_danger-navigation {
    background: #FE0000;
    color: white;
}

.btn_info-navigation {
    background: #2402EC;
    color: white;
}

.btn-navigationConsumo {
    border-radius: 0.5rem;
    padding: 0.5rem 1.2rem;
    border: none;
    font-size: 1.2rem;
    display: block;
    gap: 0.5rem;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    box-shadow: 10px 7px 6px 2px rgba(0, 0, 0, 0.2);
}

.btn-navigationConsumoInfo {
    border-radius: 0.5rem;
    padding: 0.2rem 1.2rem 3rem;
    border: none;
    font-size: 1.2rem;
    display: flex;
    gap: 2rem;
    cursor: pointer;
    text-decoration: none;
    box-shadow: 10px 7px 6px 2px rgba(0, 0, 0, 0.2);
    position: sticky;
    bottom: 0;
    justify-content: center;
}

.size-status {
    font-size: 1.5rem;
}

.bold-status {
    font-weight: bold;
}

.chart-content h2 {
    font-size: 1.3rem;
    text-align: center;
}

.selectNumber{
    display: flex;
    padding-bottom: 0.2rem;
    justify-content: center;
    grid-gap: 40%;
    position: relative;
    justify-self: center;
    margin-top: 1rem;
    width: auto !important;
}

@media only screen and (max-width: 1440px) {
    .card {
        margin-left: 7rem;
        margin-right: 9rem;
    }
}

@media only screen and (max-width: 1399px) {
    .card {
        margin-left: 5rem;
        margin-right: 6rem;
    }
}

@media only screen and (max-width: 1330px) {
    .chart-content {
        grid-template-columns: repeat(3, 1fr);
    }

    .mainResp {
        height: 1180px !important;
    }

    .barra {
        z-index: 9999;
    }

    .sidenav {
        z-index: 10000;
    }

    .card {
        margin-left: 3rem;
        margin-right: 3rem;
    }
}

@media only screen and (max-width: 1200px) {
    .chart-content {
        grid-template-columns: repeat(3, 1fr);
    }

    .mainResp {
        height: 1180px !important;
    }

    .barra {
        z-index: 9999;
    }

    .sidenav {
        z-index: 10000;
    }

    .card {
        margin-left: 1rem;
        margin-right: 3rem;
    }
}

@media only screen and (max-width: 1199px) {
    .consums-list_content {
        grid-template-columns: 1fr;
    }

    .chart-content {
        grid-template-columns: repeat(3, 1fr);
    }

    .mainResp {
        height: 1280px !important;
    }

    .barra {
        z-index: 9999;
    }

    .sidenav {
        z-index: 10000;
    }

    .card {
        margin-left: 1rem;
        margin-right: 4rem;
    }
}

@media only screen and (max-width: 991px) {
    .consums-list_content {
        grid-template-columns: 1fr;
    }

    .chart-content {
        grid-template-columns: repeat(2, 0.5fr);
    }

    .mainResp {
        height: 1580px !important;
    }

    .barra {
        z-index: 9999;
    }

    .sidenav {
        z-index: 10000;
    }

    .card {
        margin-left: -2rem;
        margin-right: -4rem;
    }
}

@media screen and (max-width: 800px) {
    .consums-list_content {
        grid-template-columns: 1fr;
    }

    .chart-content {
        grid-template-columns: repeat(2, 0.5fr);
    }

    .mainResp {
        height: 1580px !important;
    }

    .barra {
        z-index: 9999;
    }

    .sidenav {
        z-index: 10000;
    }

    .card {
        margin-left: -11rem;
        margin-right: -9rem;
    }
}

@media only screen and (max-width: 767px) {
    .mainResp {
        height: 1590px !important;
    }

    .chart-content {
        grid-template-columns: repeat(2, 0.5fr);
    }

    .barra {
        z-index: 9999;
    }

    .sidenav {
        z-index: 10000;
    }

    .card {
        margin-left: -14rem;
        margin-right: -9rem;
    }
}

@media only screen and (max-width: 575.98px) {
    .mainResp {
        height: 2500px !important;
    }

    .chart-content {
        grid-template-columns: repeat(1, 0.5fr);
    }

    .barra {
        z-index: 9999;
    }

    .sidenav {
        z-index: 10000;
    }

    .card {
        margin-left: -14rem;
        margin-right: -5rem;
    }
}