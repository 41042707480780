
.bg-pre-login{
    grid-row: 1;
    grid-column: 1 / -1;
    height: 100vh;
  }
  
  .logo{
      /* background-image: url('./images/logotipo-02.png') ; */
      width: 31%;
      /* width: 100%; */
      /* height: 100vh; */
      background-size: cover;
      /* position: relative; */
      background-repeat: no-repeat;
      flex:2;
      display:flex;
  }
  
  .contenedor {
    display: grid;
    height: 100vh;
  }
  
  .content-options{
    margin: 1rem 2rem;
    display: grid;
    justify-content: center;
    align-content: center;
    text-align: center;
  }
  
  .contenedor-pre-login {
    background-color: rgb(1, 1, 68);
    display: grid;
    grid-row: 1;
    grid-column: 1 / -1;
    z-index: 1;
    width: 33%;
    align-content: center;
  }
  
  .p-white {
    color: white;
  }
  
  .inputs-login{
    flex-direction: column;
    flex: 1;
  }
  
  .img-fondo{
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
  
  .img-logo{
    width: 70%;
    justify-self: center;
  }

  /*ESTILOS DE LA PANTALLA DE CONSUMOS Y DEL COMPÓNENTE DE LAS GRAFICAS*/

.title-uf{
  display: grid;
  text-align: center;
  justify-content: center;
  margin: 7rem 0;
  background: #F1F2F4;
}

.title-ufInfo{
  display: grid;
  text-align: center;
  justify-content: center;
  margin: 7rem 0rem 3rem 0;
  background: #F1F2F4;
}

.date-expirate{
  display: grid;
  grid-template-columns: 100px 100px 100px;
  font-size: 1.2rem;
  gap: 2rem;
  /* grid-template-columns: repeat(3, 3fr); */
  /* width:100vw; */
}

.consumosInFin{
  display: grid;
  grid-template-rows: repeat(3, 35px);
}

.color-title-consumo{
  color: blue;
  font-size: 20px;
}

.title-consumos{
  text-align: center;
  color: rgb(63, 63, 224);
  margin-top: -2rem;
}

.chart-content{
  display: grid;
  justify-content: center;
  /* align-items: center; */
  grid-template-columns: repeat(4, 4fr);
  margin-bottom: 100px;
}

.btn-consumos{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
 background-color: #4CAF50;
  /* height: 100vh; */
}

.btn-consumosInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 0;
  gap: 2rem;
}

.card-footer{
  justify-items: center;
  margin-bottom: 2rem;
}

.btns{
  font-size: 21px;
  width: 157px;
}

.btn-navigation{
  border-radius: 0.5rem;
  padding: 0.5rem 1.2rem;
  border: none;
  font-size: 1.2rem;
  display: flex;
  gap: .5rem;
  cursor: pointer;
  text-decoration: none;
  /* box-shadow: 10px 7px 6px 2px rgba(0, 0, 0, 0.2); */
}
/*FIN ESTILOS DE LA PANTALLA DE CONSUMOS Y DEL COMPÓNENTE DE LAS GRAFICAS*/

/**/
.btn-success{
  padding: 5px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}

.body-home{
  margin: 30px;
}

.btn_info-home{
  background-color: red;
}
.btn_info-recharge{
  background-color: blue;
}
/**/

/*inicio home styles*/
/*card consumos*/ 
.card{
  background-color: #eceaea;
  border-radius: 22px;
  margin-left: 10rem;
  margin-right: 10rem;
  border: 3px solid #eceaea;
  box-shadow: 0px 0px 30px grey;
}
/*finish card consumos*/ 

.prequest{
  color: rgb(63, 63, 224);
}
/*end home styles*/

  
  @media screen and (max-width: 1400px) {
    .contenedor-pre-login {
      width: 40%;
    }
  }
  
  
  @media screen and (max-width: 850px) {
    .contenedor-pre-login {
      width: 100%;
    }
  }