/* Formulario de cliente */
.container-start {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.form-client{
    display: grid;
    grid-template-columns:  1fr 1fr;
    /* gap: 20px; */ /*Descomentar si es necesario*/
    position: relative;
    top: 15rem;
    /* top: 131px; */ /*Descomentar despues*/
}
/* Formulario de cliente */

.auth-form-container-client {
    border-width: 1;
    border-color: white;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    /* box-shadow: 0px 0px 14px 0px #8c8c8c;
    -webkit-box-shadow: 0px 0px 14px 0px #8c8c8c;
    -moz-box-shadow: 0px 0px 14px 0px #8c8c8c; */
    padding: 0.5rem;
    margin: auto;
}

.form-cotainer-client-input{
    justify-content: center;
    padding: 4rem;
    /* padding-left: 7rem; */ /*Descomentar si es necesario*/
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 50px;
}

.input-email, .input-password{
    grid-column: span 2;
}

.input-form-profile {
    border: 0;
    border-bottom: 2px solid #dbdbdb;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    outline: none;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: #454545;
    font-weight: 500;
    width: 100%;
}

.label-form-profile {
    color: #454545 ;
    font-size: 1.2rem;
}

.btn-container-profile{
    padding-left: 21rem;
}


.btn-profile{
    background-color: #108b0d;
    color: white;
    border: 1px solid #49a51ad4;
    border-radius: 6px;
    cursor: pointer;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 1rem;
    padding-left: 1rem;
    width: 60%;
}

.btn-profile_close{
    background-color: #fe0000;
    color: white;
    border: 1px solid #fe0000;
    border-radius: 6px;
    cursor: pointer;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 1rem;
    padding-left: 1rem;
    width: 60%;
}

.img-robot{
    width: 80%; 
    transform: translateY(25%);
}    

/* .input-name, .input-apellido{
    width: 20rem;
} */ /*Descomentar si es necesario*/

.hr{
    width: 77%;
    border: 2px solid #e2e6e9;
    margin-top: 2rem;
}

.IoExit{
    font-size: 20px;
    margin-bottom: -0.3rem;
}

/* .pa-enlaces{
    padding-left: 4rem;
} */ /*Descomentar si es necesario*/



/* Responsivo */
@media only screen and (max-width: 1920px){
    .grid-2{
        grid-gap: 26rem;
    }
    
    .form-client{
        gap: 20px;
    }
    
    .input-name, .input-apellido, .input-pws1 {
        width: 20rem;
    }
    .form-cotainer-client-input {
        padding-left: 7rem;
    }
    .img-robot {
        margin-top: 3rem;
    }
    .pa-enlaces {
        padding-left: 4rem;
    }
}

@media only screen and (max-width: 1440px){
    .grid-2{
        grid-gap: 11rem;
    }
    
    .form-client{
        gap: 1px;
    }
    
    .input-name, .input-apellido, .input-pws1 {
        width: 14rem;
    }
    .form-cotainer-client-input {
        padding-left: 2rem;
    }
    .img-robot {
        margin-top: 3rem;
    }
    .pa-enlaces {
        padding-left: 1rem;
    }
    .btn-profile {
        width: 80%;
    }
}

@media only screen and (max-width: 1330px){
    .grid-2{
        grid-gap: 11rem;
    }
    
    .form-client{
        gap: 1px;
    }
    
    .input-name, .input-apellido, .input-pws1 {
        width: 14rem;
    }
    .form-cotainer-client-input {
        padding-left: 2rem;
    }
    .img-robot {
        margin-top: 3rem;
    }
    .pa-enlaces {
        padding-left: 1rem;
    }
    .btn-profile {
        width: 80%;
    }
}

@media only screen and (max-width: 1200px) {
    .grid-2{
        grid-gap: 11rem;
    }
    
    .form-client{
        gap: 1px;
    }
    
    .input-name, .input-apellido, .input-pws1 {
        width: 14rem;
    }
    .form-cotainer-client-input {
        padding-left: 2rem;
    }
    .img-robot {
        margin-top: 3rem;
    }
    .pa-enlaces {
        padding-left: 1rem;
    }
}

@media only screen and (max-width: 1199px){
    .grid-2{
        grid-gap: 11rem;
    }
    
    .form-client{
        gap: 1px;
    }
    
    .input-name, .input-apellido, .input-pws1 {
        width: 14rem;
    }
    .form-cotainer-client-input {
        padding-left: 2rem;
    }
    .img-robot {
        margin-top: 3rem;
    }
    .pa-enlaces {
        padding-left: 1rem;
    }
    .sidenav a {
        font-size: 16px;
    }
    .btn-profile {
        width: 80%;
    }
    .navbar-items-client {
        padding-left: 0rem;
    }
    .perfil-user {
        width: 59%;
    }
}

@media only screen and (max-width: 991px){
    .grid-2{
        grid-gap: 2rem;
    }
    
    .form-client{
        gap: 1px;
    }
    
    .input-name, .input-apellido, .input-pws1 {
        width: 9rem;
    }
    .form-cotainer-client-input {
        padding-left: 2rem;
    }
    .img-robot {
        margin-top: 3rem;
    }
    .pa-enlaces {
        padding-left: 0rem;
    }
    .sidenav a {
        font-size: 16px;
    }
    .btn-profile {
        width: 100%;
    }
    .navbar-items-client {
        padding-left: 0rem;
    }
    .perfil-user {
        width: 59%;
    }
    .btn-profile_close{
        width: 100%;
    }
}

@media only screen and (max-width: 767px){
    .grid-2{
        grid-gap: 2rem;
    }
    
    .form-client{
        gap: 1px;
    }
    
    .input-name, .input-apellido, .input-pws1 {
        width: 9rem;
    }
    .form-cotainer-client-input {
        padding-left: 2rem;
    }
    .img-robot {
        margin-top: 3rem;
    }
    .pa-enlaces {
        padding-left: 0rem;
    }
    .sidenav {
        width: 0%;
    }
    #main {
        margin-left: 0%;
    }
    .btn-profile {
        width: 100%;
    }
    .btn-profile_close{
        width: 100%;
    }
}

@media only screen and (max-width: 575.98px){
    .navbar-items-client {
        padding-left: 0rem;
    }
    .img-robot{
        display: none;
    }
    .btn-profile {
        width: 100%;
    }
    .btn-profile_close{
        width: 100%;
    }
}