.numbers-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10%;
}

.number-container{
    margin: 1rem;
    border: 3px solid #4556cd;
    padding: 1rem;
    border-radius: 1rem;
    color: yellow;
    background: #dcd5ff;
}

.link-number{
    text-decoration: none;
    font-size: 1.5rem;
    text-align: center;
    color: #4949cf;
    font-weight: bold;
}

.p-alert{
    align-self: center;
    background: #eeeea2;
    padding: 1rem;
    font-size: 1.2rem;
    color: #707070;
}