.navbar-container {
    width: 100%;
    height: 4rem;
    background-image: url("../img/7-Pagina-Pleca-superior-short2.jpg");
    background-position: top;
    background-size: 100% auto;
    background-repeat: no-repeat;
    top: 0;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    color: white;
}

.navbar-container-principal {
    width: 100%;
    height: 4rem;
    background-color: #F3F6F9;
    /* background-image: url("../img/nav-principal.jpg"); */
    background-position: top;
    background-size: 100% auto;
    background-repeat: no-repeat;
    top: 0;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    color: white;
    box-shadow: 0px 0px 14px 0px #8c8c8c;
    -webkit-box-shadow: 0px 0px 14px 0px #8c8c8c;
    -moz-box-shadow: 0px 0px 14px 0px #8c8c8c;
}

.navbar-container-clients {
    width: 100%;
    height: 6rem;
    background-color: #000638;
    /* background-image: url("../img/nav-principal.jpg"); */
    background-position: top;
    background-size: 100% auto;
    background-repeat: no-repeat;
    top: 0;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    color: white;
    box-shadow: 0px 0px 14px 0px #8c8c8c;
    -webkit-box-shadow: 0px 0px 14px 0px #8c8c8c;
    -moz-box-shadow: 0px 0px 14px 0px #8c8c8c;
}

.navbar-container-name {
    width: 100%;
    height: 8rem;
    background-color: #f1f2f4;
    background-position: top;
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: absolute;
    top: 113px;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    color: white;
    box-shadow: 0px 0px 14px 0px #8c8c8c;
    -webkit-box-shadow: 0px 0px 14px 0px #8c8c8c;
    -moz-box-shadow: 0px 0px 14px 0px #8c8c8c;
}

.grid-2{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* grid-gap: 26rem; */ /*descomentar si es necesario*/
    grid-auto-rows: minmax(100px, auto);
}

.perfil-user{
    /* width: 23%;
    border-radius: 50%; */
    height: 116%;
    width: 65%;
    border-radius: 50%;
}

@-moz-document url-prefix() {
    .perfil-user {
        width: 48%;
    }
}

.name-client{
    color: #000;
}

.navbar-items-client {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding-left: 5rem;
}

.logo-client {
    width: 8rem;
    margin: 0.2rem;
}

/*Navar inicio*/
.sidenav {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 20%;
    background-color: #f1f1f1;
    position: fixed;
    height: 100%;
    overflow: auto;
    transition: 0.5s;
    top: 0;
    z-index: 1;
}

.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 20px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.sidenav a:hover, .offcanvas a:focus{
    color: #201e1e;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 6px;
    font-size: 36px;
    margin-left: 50px;

    background-color: transparent;
    color: #fff;
    border: transparent;
    cursor: pointer;
}

.img-menu{
    width: 100%;
}

#main {
    margin-left:20%;
    /* height:1000px; */
    transition: margin-left .5s;
    overflow:hidden;
}

body {
  overflow-x: hidden;
}

.topnav {
    background-color: #000638;
    overflow: hidden;
    height: 7rem;
}

.topnav .openbtn {
    float: right;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    background-color: transparent;
    border: transparent;
    cursor: pointer;
}

.topnav a:hover {
    color: black;
}

#icoOpen{
    background-color: #fff;
}

.topnav a.active {
    background-color: #4CAF50;
    color: white;
}

@media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
}

#ico{
  display: none;
}
.menu {
    background: #000;
    display: none;
    padding: 5px;
    width: 320px;
    border-radius: 5px;
    transition: all 0.5s ease;
  }
  .menu a {
    display: block;
    color: #fff;
    text-align: center;
    padding: 10px 2px;
    margin: 3px 0;
    text-decoration: none;
    background: #444;
  }
  .menu a:nth-child(1) {
    margin-top: 0;
    border-radius: 3px 3px 0 0 ;
  }
  .menu a:nth-child(5) {
    margin-bottom: 0;
    border-radius: 0 0 3px 3px;
  }
  .menu a:hover {
    background: #555;
  }
  .barra{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    /* display: none; */
}

.barra .mostrar{
    display: block;
}
/*navar fin*/

/* Margin */
    .mt-3{
        margin-top: 3rem;
    }
    .ml-2{
        margin-left: 2rem;
    }
/* Margin */

/* padding */
    .pl-5{
        padding-left: 5rem;
    }
    .pl-1{
        padding-left: 1rem;
    }
/* padding */

.navbar-items {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
}

.nav-link {
    color: white;
    font-weight: bold;
    font-size: 1.1rem;
    text-decoration: none;
    text-transform: uppercase;
}

.nav-link-principal {
    color: #3c54bc;
    font-weight: bold;
    font-size: 1.1rem;
    text-decoration: none;
    text-transform: uppercase;
}

.user {
    width: 15%;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.toggle-btn {
    cursor: pointer;
    display: none;
}

.sidebar {
    display: none;
}

.username {
    font-weight: bold;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
}

.btn-logout {
    width: 12%;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    cursor: pointer;
    border-radius: 20px;
    border: none;
}

.logout-img {
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .navbar-container {
        width: 100%;
        height: 4rem;
        background-image: url("../img/7-Pagina-Pleca-superior-short2.jpg");
        background-position: top;
        background-size: 170% 100%;
        background-repeat: no-repeat;
    }

    .navbar-container-principal {
        width: 100%;
        height: 4rem;
        background-color: #F3F6F9;
        background-position: top;
        background-size: 170% 100%;
        background-repeat: no-repeat;
    }

    .user {
        display: none;
    }

    .sidebar {
        display: flex;
        flex-direction: column;
        position: fixed;
        width: 85%;
        height: 100%;
        background-color: #F3F6F9;
        border-radius: 10px;
        right: -85%;
        top: 0;
        transition: all 100ms linear;
        box-shadow: 0 0 15px #3c54bc;
    }

    .sidebar ul li {
        width: 100%;
        color: rgba(230, 230, 230, 0.9);
        text-align: center;
        border-bottom: 1px solid rgba(100, 100, 100, 0.3);
        padding: 15px 10px;
        list-style: none;
    }

    .sidebar ul {
        width: 100%;
        padding-left: 0;
    }

    .sidebar > ul > li {
        color: #3c54bc;
        font-weight: 700;
    }

    .toggle-btn {
        cursor: pointer;
        display: flex;
        background-color: #FFFFFF;
        border-radius: 7px;
        border: none;
        position: absolute;
        right: 104%;
        top: 0.7rem;
        box-shadow: 0 0 15px #3c54bc;
    }

    .toggle-btn-active {
        right: 4% !important;
    }

    .toggle-btn span {
        display: block;
        width: 1.5rem;
        text-align: center;
        font-size: 1.5rem;
        color: #3c54bc;
    }

    .sidebar-active {
        right: 0 !important;
    }

    .nav-link {
        display: none;
    }

    .nav-link-principal {
        display: none;
    }

    .nav-link-responsive {
        color: #3c54bc;
        text-decoration: none;
    }

    .user-responsive {
        width: 100% !important;
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
    }

    .username {
        font-weight: bold;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        color: #3c54bc;
        margin-bottom: 0.5rem;
    }
     .btn-logout {
        width: 10%;
        box-shadow: 0 0 10px #3c54bc;
     }
}