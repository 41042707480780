.banner-modal {
    width: 100%;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-around;
    align-items: center;
    position: absolute;
}

.title-banner-modal {
    text-transform: uppercase;
}

.item-banner-modal {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
}

.banner-container {
    width: 100%;
    height: 5rem;
    background-image: url("../img/7-Pagina-Pleca-superior-short2.jpg");
    background-position: top;
    background-size: 100% auto;
    background-repeat: no-repeat;
    top: 0;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    color: white;
}

.banner-card {
    width: 25%;
    background-color: #F3F6F9;
    margin: auto;
    border-radius: 10px;
    border: none;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 14px 0px #8c8c8c;
    -webkit-box-shadow: 0px 0px 14px 0px #8c8c8c;
    -moz-box-shadow: 0px 0px 14px 0px #8c8c8c;
}

.banner-card-title {
    color: #545453;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    text-align: center;
}

.banner-card-title-bold {
    color: #545453;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    font-weight: 700;
}

.banner-card-title-h1 {
    color: #3c54bc;
    margin: auto;
    text-align: center;
}

.recharges-container {
    width: 95%;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    /* column-count: 3; */
    justify-content: space-around;
}

.recharge-item {
    background-color: #F3F6F9;
    border-radius: 10px;
    border: none;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
    /* padding-top: 1rem; */
    padding-bottom: 1rem;
    padding-left: 0rem;
    padding-right: 0rem;
    flex-basis: calc(20%);
    box-sizing: border-box;
    /* break-inside: avoid; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.recharge-item:active, .recharge-item:active > .banner-card-title-bold, .recharge-item:active > .banner-card-title  {
    background-color: #ADBAC0;
    color: white !important;
}

.band-item {
    width: 100%;
    background-color: #fcbc04;
    border-radius: 7px 7px 0px 0px;
    /* background-color: white; */
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 14px 0px #8c8c8c;
    -webkit-box-shadow: 0px 0px 14px 0px #8c8c8c;
    -moz-box-shadow: 0px 0px 14px 0px #8c8c8c;
}

.product-item {
    margin-top: 0.3rem;
    margin-bottom: 0.2rem;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
    /* transform: translateX(15%); */
}

.ticket {
    width: 25%;
    background-color: #F3F6F9;
    margin: auto;
    border: none;
    border-radius: 15px;
}

.ticket-body {
    width: 90%;
    background-color: #F3F6F9;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.ticket-footer {
    width: 90%;
    background-color: #80b4ec;
    border: 1px solid #ccc;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}


.ticket-top-left {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    left: -10px;
    top: -10px;
    border: 2px solid transparent;
    background-color: #FFF;
    position: absolute;
}

.ticket-top-right {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    right: -10px;
    top: -10px;
    border: 2px solid transparent;
    background-color: #FFF;
    position: absolute;
}

.check {
    width: 12%;
}

.title-ticket {
    color: #545453;
}

.subtitle-ticket {
    color: #545453;
    font-size: 1.4rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
}

.subtitle-ticket > span {
    color: #FFFFFF;
}

.label-ticket {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    font-weight: 400;
    text-align: center;
    font-size: 1.2rem;
    color: #545453;
}

.label-ticket > span {
    color: #3c54bc;
    font-weight: 700;
}

.ticket-price {
    color: #FFFFFF;
    font-size: 3rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
}

@media only screen and (max-width: 600px) {
    .banner-container {
        width: 100%;
        height: 4rem;
        background-image: url("../img/7-Pagina-Pleca-superior-short2.jpg");
        background-position: top;
        background-size: 170% 100%;
        background-repeat: no-repeat;
        top: 0;
        margin-bottom: 1.2rem;
    }

    .title-banner-modal {
        font-size: 1.3rem;
    }

    .banner-card {
        width: 85%;
    }

    .recharges-container {
        width: 95%;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        /* flex-wrap: wrap; */
        /* column-count: 3; */
        justify-content: center;
    }

    .ticket {
        width: 85%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2rem;
    }

    .title-ticket {
        font-size: 1.2rem;
    }
    
    .subtitle-ticket {
        font-size: 1.2rem;
    }

    .label-ticket {
        font-size: 1rem;
    }

    .banner-card-title {
        font-size: 1.1rem;
        text-align: center;
    }
    
    .banner-card-title-h1 {
        font-size: 1.5rem;;
    }
}