.App {
  text-align: center;
}

.App-logo {
  width: 8rem;
  pointer-events: none;
  margin: 1rem;
}

.logo {
  width: 4rem;
  margin: 0.2rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /* animation: App-logo-spin infinite 20s linear; */
  }
}

.App-header {
  background-color: #FFFFFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);

}

.container-pages {
  width: 100%;
  display: block;
  flex-direction: row;
  justify-content: center;
}

.container-center {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.title {
  color: #3c54bc !important;
  font-size: 25;
  font-weight: 800;
}

.label-form {
  color: #3c54bc !important;
}

@media only screen and (max-width: 600px) {
  .App-logo {
    width: 7rem;
    pointer-events: none;
    margin: 1rem;
  }
}