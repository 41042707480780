.img-icon {
    width: 18px;
    margin: auto;
}

.form {
    border-width: 1;
    border-color: white;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
    box-shadow: 0px 0px 14px 0px #8c8c8c;
    -webkit-box-shadow: 0px 0px 14px 0px #8c8c8c;
    -moz-box-shadow: 0px 0px 14px 0px #8c8c8c;
    padding: 1rem;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.form-row {
    border-width: 1;
    border-color: white;
    border-radius: 7px;
    display: flex;
    /* flex-direction: row; */
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    box-shadow: 0px 0px 14px 0px #8c8c8c;
    -webkit-box-shadow: 0px 0px 14px 0px #8c8c8c;
    -moz-box-shadow: 0px 0px 14px 0px #8c8c8c;
    padding: 1rem;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.title-form {
    color: #545453;
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.title-h1 {
    color: #3c54bc;
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    animation: wave 3s linear infinite;
}

@keyframes wave {
    0% { color: #3c54bc; }
    50% { color: #F3F6F9; }
    100% { color: #3c54bc; }
  }

.label-form {
    width: 100%;
    color: #545453 !important;
    font-weight: 700;
}

.input-form-group {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.input-form {
    width: 100%;
    background-color: #F3F6F9;
    border-radius: 15px;
    border-color: transparent;
    margin-top: 1rem;
    width: 100%;
    font-size: 18px;
    color: #3c54bc;
    font-weight: bold;
    padding: 0.2rem;
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.btn-primary {
    background-color: #3c54bc;
    color: white;
    font-weight: bold;
    border: none;
    padding: 0.5rem;
    width: 40%;
    border-radius: 15px;
    margin: 1rem;
    cursor: pointer;
}

.btn-danger {
    background-color: #dc3545;
    color: white;
    font-weight: bold;
    border: none;
    padding: 0.5rem;
    width: 40%;
    border-radius: 15px;
    margin: 1rem;
    cursor: pointer;
}

.btn-primary:active {
    background-color: #0056b3;
}

.btn-close {
    background-color: white;
    color:#3c54bc;
    font-weight: 900;
    border: none;
    border-radius: 7px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    cursor: pointer;
}

.btn-close:active {
    background-color: #ADBAC0;
    color: white;
}

.btn-primary-outline {
    background-color: #FFFFFF;
    border: 1px solid #3c54bc;
    border-radius: 10px;
    padding: 0.5rem;
    margin: 1rem;
    color: #3c54bc;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
}

.btn-primary-outline:active {
    background-color: #3c54bc;
    color: #FFFFFF;
    border: none;
}

.btn-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.col-12-cust {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.custom-col {
    padding: 1rem;
    margin: auto;
    box-sizing: border-box;
}

.col-2 {
    margin: auto;
    width: 16.66667% !important;
}

.col-3 {
    margin: auto;
    width: 25% !important;
}

.col-4 {
    margin: auto;
    width: 33.33333% !important;
}

.col-6 {
    margin: auto;
    width: 50% !important;
}

.col-12 {
    margin: auto;
    /* padding-left: 1rem; */
    /* padding-right: 1rem; */
    width: 100% !important;
}

.d-flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-space-between {
    justify-content: space-between;
}

.align-items {
    align-items: center;
}

.form-group {
    display: flex;
    flex-direction: column;
    justify-content:start;
    align-items: center;
    /* padding-left: 0.2rem; */
    /* padding-right: 0.2rem; */
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.input-group {
    width: 80%;
    background-color: #F3F6F9;
    border-radius: 15px;
    border-color: transparent;
    margin-top: 1rem;
    font-size: 15px;
    color: #3c54bc;
    font-weight: 600;
    padding: 0.2rem;
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.input-group:focus {
    box-shadow: 0 0 15px #3c54bc;
}

.label-group {
    width: 80%;
    color: #545453 !important;
    font-weight: 700;
    font-size: 14px;
}

.border-bottom {
    border: none;
    border-bottom: 1px solid #545453;
}

.my {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.subtitle-form {
    font-size: 17px;
    color: #545453;
    text-align: center;
}

.btn-options-container {
    display: flex;
    justify-content: space-between;
}

.btn-options {
    width: 25%;
    height: auto;
    background-color: #F3F6F9;
    color: #3c54bc;
    border: none;
    text-align: center;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    margin: 1rem 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0px 0px 17px 6px rgba(60,84,188,0.68);
    -webkit-box-shadow: 0px 0px 17px 6px rgba(60,84,188,0.68);
    -moz-box-shadow: 0px 0px 17px 6px rgba(60,84,188,0.68);
}

.logo-btn {
    width: 3rem;
    margin: auto;
}

.btn-options:active {
    background-color: #cfcfcf;
}

@media only screen and (max-width: 600px) {

    .ReactModal__Content {
        transform: translate(-9%, -3%) !important;
    }

    .form {
        width: 85%;
        padding: 1rem;
        margin: auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .btn-close {
        font-size: 1rem;
        padding-left: 0.7rem;
        padding-right: 0.7rem;
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
    }

    .form-row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 85%;
        padding: 1rem;
    }

    .form-group {
        display: flex;
        flex-direction: column;
        justify-content:start;
        align-items: center;
        /* padding-left: 0.2rem; */
        /* padding-right: 0.2rem; */
        margin-top: 0.5rem;
        margin-bottom: 1.2rem !important;
    }

    .input-group {
        width: 85%;
        border-radius: 20px;
        margin-top: 0.5rem;
        font-size: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0.4rem;
        padding-right: 0.4rem;
    }
    .label-group {
        width: 85%;
        font-size: 1rem;
    }

    .col-4 {
        margin: auto;
        width: 100% !important;
    }

    .col-6 {
        margin: auto;
        width: 100% !important;
    }

    .col-12 {
        margin-top: 0rem;
        margin-bottom: 0.5rem;
        margin-left: auto;
        margin-right: auto;
        width: 100% !important;
    }

    .btn-primary, .btn-danger {
        border-radius: 20px;
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
        padding-left: 0.6rem;
        padding-right: 0.6rem;
        width: 60%;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
    }

    .subtitle-form {
        font-size: 1.2rem;
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
    }

    .btn-options-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .btn-options {
        width: 85%;
        margin: 1rem auto;
    }

    .title-h1 {
        font-size: 1.4rem;
    }
}