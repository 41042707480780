.recharge-container{
    background: #F1F2F4;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.recharge-container{
    background: #F1F2F4;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.recharge-title{
    color: #01669C;
    font-size: 2rem;
    font-weight: 900;
    margin: 2rem 0;
    text-transform: uppercase;
    text-align: center;
}

.recharge-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    height: 100vh;
}

.recharge_form{
    width: 60%;
}

.recharge_btn{
    padding: 0.3rem;
    width: 100%;
    margin: 1rem 0;
    border-radius: 1rem;
    background: #01669C;
    color: white;
    border: none;
    font-size: 1.2rem;
    font-weight: 700;
}

.recharge-select{
    width: 80%;
    text-align: center;
    padding: 1rem;
    border-radius: 1rem;
    border: solid #177be199 3px;
    font-size: 1.1rem;
    cursor: pointer;
}

.recharge-select > option {
    background: #f3f3f3;
}