.auth-form-container {
    border-width: 1;
    border-color: white;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    box-shadow: 0px 0px 14px 0px #8c8c8c;
    -webkit-box-shadow: 0px 0px 14px 0px #8c8c8c;
    -moz-box-shadow: 0px 0px 14px 0px #8c8c8c;
    padding: 0.5rem;
    margin: auto;
}

.title-form-login {
    color: #3c54bc !important;
    font-size: 1.5rem;
    font-weight: 800;
}

.label-form-login {
    color: #3c54bc !important;
    font-size: 1.2rem;
}

.label-danger {
    color: #FF0000 !important;
    font-size: 1rem;
    margin-bottom: 1rem;
    text-align: center;
}

.label-success {
    color: #34B912 !important;
    font-size: 1rem;
    margin-bottom: 1rem;
}

.input-form-login {
    border: 0;
    border-bottom: 1px solid #3c54bc;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    outline: none;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: #3c54bc;
    font-weight: 500;
    /* border-bottom-color:  !important; */
}

.btn-login {
    background-color: #3c54bc;
    color: white;
    border: 1px solid #3c54bc;
    border-radius: 15px;
    cursor: pointer;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 1rem;
    padding-left: 1rem;
    width: 50%;
}



@media only screen and (max-width: 600px) {
    .auth-form-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 85%;
        padding: 0.5rem;
        margin: auto;
    }

    .title-form-login {
        color: #3c54bc !important;
        font-size: 1.2rem;
    }

    .label-form-login {
        color: #3c54bc !important;
        font-size: 1rem;
    }

    .input-form-login {
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
    }
}